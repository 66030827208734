import { Delivery, WifiController, AugmentedReality, Location } from "@carbon/icons-react";

const routes = (isLoggedIn) => [
	{
		path: "hc20/assets",
		title: "Assets",
		icon: <AugmentedReality />,
		visible: true
	},
	{
		path: "hc20/bees",
		title: "Bees",
		icon: <WifiController />,
		visible: true
	},
	{
		path: "hc20/shipments",
		title: "Shipments",
		icon: <Delivery />,
		visible: true
	},
	{
		path: "hc20/ni",
		title: "Node Intelligence",
		icon: <Location />,
		visible: true
	}
];

export default routes;
